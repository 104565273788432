import React, { useEffect, useState } from "react";
import Layout from "layout/MainLayout";
import ProductFilterForm from "Forms/ProductFilterForm";
import useClient from "hooks/useClient";
import useSnackMsg from "hooks/useSnackMsg";
import { CircularProgress } from "@mui/material";
import ProductCard from "components/cards/ProductCard";
import Modal from "components/common/base/Modal";
import FruVegFiberGrains from "Forms/addProducts/FruVegFiberGrains";
import FertilizerPlant from "Forms/addProducts/FertilizerPlant";
import Seed from "Forms/addProducts/Seed";
import Machinary from "Forms/addProducts/Machinary";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { imgUrl } from "helpers/path";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getProductDetails } from "../redux/slices/products/productsActions"; 

function getCategoryComponent(
  val,
  onSubmit,
  addProLoader,
  images,
  handleImagesChange
) {
  if (
    val === "Fruits" ||
    val === "Vegetables" ||
    val === "Fiber & Oil Seed Crops" ||
    val === "Grains & Cereals"
  ) {
    return (
      <FruVegFiberGrains
        selectedCate={val}
        onSubmit={onSubmit}
        loader={addProLoader}
        images={images}
        onImages={handleImagesChange}
      />
    );
  }
  if (
    val === "Fertilizers" ||
    val === "Crop Nutritions" ||
    val === "Pesticides"
  ) {
    return (
      <FertilizerPlant
        selectedCate={val}
        onSubmit={onSubmit}
        loader={addProLoader}
        images={images}
        onImages={handleImagesChange}
      />
    );
  }
  if (val === "Seed Varieties") {
    return (
      <Seed
        selectedCate={val}
        onSubmit={onSubmit}
        loader={addProLoader}
        images={images}
        onImages={handleImagesChange}
      />
    );
  }
  if (val === "Machinary & Tools") {
    return (
      <Machinary
        selectedCate={val}
        onSubmit={onSubmit}
        loader={addProLoader}
        images={images}
        onImages={handleImagesChange}
      />
    );
  }
}

const categoryData = [
  {
    id: 1,
    name: "Crop Nutritions",
    val: "Crop Nutritions",
    img: imgUrl + "/category/SeedVarieties.png",
  },
  {
    id: 2,
    name: "Fertilizers",
    val: "fertilizers",
    img: imgUrl + "/category/fertilizer.png",
  },
  {
    id: 3,
    name: "Pesticides",
    val: "pesticides",
    img: imgUrl + "/category/Pesticide.png",
  },
];

const Products = () => {
  const [filterLoader, setFilterLoader] = useState(false);
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { api } = useClient();
  const { eSnack, sSnack } = useSnackMsg();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newFlg, setNewFlag] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [msg, setMsg] = useState("Please search...");
  const [images, setImages] = useState([]);
  const [addProLoader, setAddProLoader] = useState(false);

  const handleSearch = (val) => {
    const payload = {
      status: val.status ? val.status : undefined,
      productType: val.productType ? val.productType : undefined,
      query: val.query ? val.query : undefined,
      category: val.category ? val.category : undefined,
      subCategory:
        val.category && val.subCategory ? val.subCategory : undefined,
      composition:
        val.composition && val.composition.length > 0
          ? val.composition
          : undefined,
      brand: val.brand ? val.brand : undefined,
    };

    setData([]);
    setFilterLoader(true);
    api
      .post(`/api/tech/all/products`, payload)
      .then((res) => {
        setFilterLoader(false);
        const response = res.data && res.data.data ? res.data.data : [];
        setData(response);
        setMsg(
          response && response.length === 0 ? "Sorry no product available" : ""
        );
      })
      .catch((err) => {
        setFilterLoader(false);
        eSnack(err.message ? err.message : "Sorry something is went wrong");
      });
  };

  useEffect(() => {
    handleSearch({ status: null, productType: null });
  }, []);

  const onSubmit = (val) => {
    Object.assign(val, {
      productType: selectedCategory,
      isAlreadyExists: true,
      category: selectedCategory,
    });
    const formData = new FormData();
    Object.keys(val).forEach((key) => {
      formData.append(key, val[key]);
    });
    images.forEach((image, index) => {
      formData.append(`images`, image);
    });
    setAddProLoader(true);
    api
      .postFormData(`/api/tech/add/product`, formData)
      .then((res) => {
        setImages([]);
        setModalOpen(false);
        setAddProLoader(false);
        sSnack("Successfully product is added ");
      })
      .catch((err) => {
        setImages([]);
        setModalOpen(false);
        setAddProLoader(false);
        eSnack(err.message ? err.message : "Sorry something is went wrong");
      });
  };

  const handleImagesChange = (file) => {
    const img = file[0];
    setImages([...images, img]);
  };

  const handleDetailProduct = (x) => {
    const payload = {
      id: x.id && x.id,
      productType: x.ProductType && x.ProductType,
    };
    dispatch(getProductDetails(payload));
    navigate("/products/details");
  };

  const handleSelectCategory = async (val) => {
    if (!val) return;
    await setSelectedCategory(val);
    setModalOpen(true);
  };

  return (
    <>
      <Layout>
        <div className="p-4">
          {!newFlg ? (
            <>
              <ProductFilterForm
                handleSearch={handleSearch}
                loader={filterLoader}
                onNew={() => setNewFlag(true)}
              />
              {filterLoader && (
                <div className="flex justify-center mt-10">
                  <CircularProgress sze={28} style={{ color: "#668968" }} />
                </div>
              )}
              {!filterLoader && msg && (
                <div className="flex mt-10">
                  <p className="font-semibold font-RobotoBold text-primary text-[18px]">
                    {msg}
                  </p>
                </div>
              )}
              <div className="grid grid-cols-3 mt-10 gap-5">
                {!filterLoader &&
                  data &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <div className="col-span-1" key={index}>
                      <ProductCard
                        onDetails={handleDetailProduct}
                        data={item}
                        loader={false}
                      />
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <>
              <div className="flex mb-2 z-10 bg-white sticky ">
                <div
                  className="shadow-dashboard p-2 rounded-lg flex items-center cursor-pointer"
                  onClick={() => setNewFlag(false)}
                >
                  <ArrowBackIcon />
                </div>
              </div>

              <div className="grid grid-cols-12 gap-5 mt-5">
                {categoryData.map((item) => (
                  <div
                    className="2xl:col-span-3 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6 xs:col-span-12 p-2 border-2 hover:border-2 cursor-pointer hover:border-primary shadow-card rounded-2xl flex flex-col justify-center items-center"
                    key={item.id}
                    onClick={() => handleSelectCategory(item.name)}
                  >
                    <img
                      src={item.img}
                      alt={item.name}
                      draggable={false}
                      className="rounded-md max-h-[100px] object-contain min-h-[150px] min-w-[100px]"
                    />
                    <p className="mt-5 font-Roboto text-[16px]">{item.name}</p>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Layout>
      <Modal
        isOpen={modalOpen}
        title={`Add New Product`}
        toggle={() => {
          setImages([]);
          setModalOpen(false);
        }}
        fullWidth={true}
      >
        {getCategoryComponent(
          selectedCategory,
          onSubmit,
          addProLoader,
          images,
          handleImagesChange
        )}
      </Modal>
    </>
  );
};

export default Products;

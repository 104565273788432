import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice/authReducer";
import productsReducer from "./slices/products/productsReducer";
import referralReducer from "./slices/referrals/referralSlice"; // Changed to referralReducer

export const rootReducer = combineReducers({
  auth: authReducer,
  products: productsReducer,
  referral: referralReducer, // Updated to match import name
});

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "layout/MainLayout";
import Modal from "components/common/base/Modal";
import {
  getAllReferralCodes,
  generateReferralCode,
  updateReferralCode,
  deleteReferralCode,
} from "../redux/slices/referrals/referralSlice"; // Updated import path
import { CircularProgress } from "@mui/material";
import useSnackMsg from "hooks/useSnackMsg";
import { Button } from "../components/common/base/button";

const ReferralCode = () => {
  const dispatch = useDispatch();
  const { eSnack, sSnack } = useSnackMsg();
  const [modalOpen, setModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [daysToAdd, setDaysToAdd] = useState("");
  const [totalAttemptsToAdd, setTotalAttemptsToAdd] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [referralToDelete, setReferralToDelete] = useState("");

  const {
    referralCodes = [],
    loading,
    generateLoading,
  } = useSelector((state) => state.referral);

  useEffect(() => {
    dispatch(getAllReferralCodes());
  }, [dispatch]);

  const handleGenerateReferral = async () => {
    try {
      const data = {
        phone: phoneNumber,
        maxDays: parseInt(daysToAdd),
        maxUsage: parseInt(totalAttemptsToAdd),
      };
      const res = await dispatch(generateReferralCode(data));
      if (res.error) {
        eSnack(res.payload.message);
      } else {
        sSnack("Referral code updated successfully!");
      }
      setModalOpen(false);
      dispatch(getAllReferralCodes());
    } catch (error) {
      eSnack("Failed to generate referral code. Please try again.");
    }
  };

  const handleEditReferral = async () => {
    try {
      const data = {
        referredBy: phoneNumber,
        daysToAdd: parseInt(daysToAdd),
        increaseUsage: parseInt(totalAttemptsToAdd),
      };

      const res = await dispatch(updateReferralCode(data));
      if (!res.error) {
        sSnack("Referral code updated successfully!");
      }
      console.log(res, "data");
      setEditModal(false);
      dispatch(getAllReferralCodes());
    } catch (error) {
      console.log(error, "error updating");

      eSnack("Failed to update referral code. Please try again.");
    }
  };

  const handleEditClick = (item) => {
    setPhoneNumber(item.phone);
    setDaysToAdd("");
    setTotalAttemptsToAdd("");
    setEditModal(true);
  };

  const handleDeleteClick = (phone) => {
    setReferralToDelete(phone);
    setDeleteModal(true);
  };

  const confirmDeleteReferral = async () => {
    try {
      await dispatch(deleteReferralCode(referralToDelete)).unwrap();
      sSnack("Referral code deleted successfully!");
      setDeleteModal(false);
      dispatch(getAllReferralCodes());
    } catch (error) {
      eSnack("Failed to delete referral code. Please try again.");
    }
  };

  return (
    <>
      <Layout>
        <div className="p-4">
          <h1 className="text-2xl font-bold text-center mb-6">
            Referral Codes
          </h1>

          <Button
            value="Generate Referral Code"
            onClick={() => setModalOpen(true)}
            width={220}
            height={45}
          >
            Generate Referral Code
          </Button>

          <div className="overflow-x-auto mt-6">
            {loading ? (
              <div className="flex justify-center mt-10">
                <CircularProgress size={28} style={{ color: "#668968" }} />
              </div>
            ) : (
              <table className="min-w-full border-collapse">
                <thead>
                  <tr className="bg-gray-100 border-b">
                    <th className="text-left p-4 font-semibold">Referred By</th>
                    <th className="text-left p-4 font-semibold">
                      Referral Code
                    </th>
                    <th className="text-left p-4 font-semibold">Expiry Date</th>
                    <th className="text-left p-4 font-semibold">
                      Attempts Use
                    </th>
                    <th className="text-left p-4 font-semibold">
                      Total Attempts
                    </th>
                    <th className="text-left p-4 font-semibold">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {referralCodes.length > 0 ? (
                    referralCodes.map((item, index) => (
                      <tr key={index} className="border-b hover:bg-gray-50">
                        <td className="p-4">{item.phone}</td>
                        <td className="p-4">{item.referralCode}</td>
                        <td className="p-4">
                          {new Date(item.expiresAt).toLocaleDateString()}
                        </td>
                        <td className="p-4">{item.usageCount}</td>
                        <td className="p-4">{item.maxUsage}</td>
                        <td className="p-4">
                          <button
                            className="bg-primary text-white px-3 py-1.5 rounded-lg"
                            onClick={() => handleEditClick(item)}
                          >
                            Edit
                          </button>
                          <button
                            className="bg-red-600 text-white px-3 py-1.5 rounded-lg ml-3"
                            onClick={() => handleDeleteClick(item.phone)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center p-4">
                        No referral codes available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>

          {/* Generate Referral Modal */}
          <Modal
            isOpen={modalOpen}
            toggle={() => setModalOpen(false)}
            title="Generate Referral Code"
          >
            <div className="p-4">
              <input
                type="text"
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="border p-2 rounded-md w-full mb-4"
              />
              <input
                type="number"
                placeholder="Days"
                value={daysToAdd}
                onChange={(e) => setDaysToAdd(e.target.value)}
                className="border p-2 rounded-md w-full mb-4"
              />
              <input
                type="number"
                placeholder="Attempts"
                value={totalAttemptsToAdd}
                onChange={(e) => setTotalAttemptsToAdd(e.target.value)}
                className="border p-2 rounded-md w-full mb-4"
              />
              <Button
                onClick={handleGenerateReferral}
                value="Generate"
                disabled={generateLoading}
              />
            </div>
          </Modal>

          {/* Edit Referral Modal */}
          <Modal
            isOpen={editModal}
            toggle={() => setEditModal(false)}
            title="Edit Referral Code"
          >
            <div className="p-4">
              <div>
                <label>Days to Add:</label>
                <input
                  type="number"
                  placeholder="Days"
                  value={daysToAdd}
                  onChange={(e) => setDaysToAdd(e.target.value)}
                  className="border p-2 rounded-md w-full mb-4"
                />
              </div>
              <div>
                <label>Total Attempts to Add:</label>
                <input
                  type="number"
                  placeholder="Attempts"
                  value={totalAttemptsToAdd}
                  onChange={(e) => setTotalAttemptsToAdd(e.target.value)}
                  className="border p-2 rounded-md w-full mb-4"
                />
              </div>
              <Button onClick={handleEditReferral} value="Update" />
            </div>
          </Modal>

          {/* delete */}
          <Modal
            isOpen={deleteModal}
            toggle={() => setDeleteModal(false)}
            title="Confirm Deletion"
          >
            <div className="p-4">
              <p>Are you sure you want to delete this referral code?</p>
              <Button onClick={confirmDeleteReferral} value="Yes, Delete" />
            </div>
          </Modal>
        </div>
      </Layout>
    </>
  );
};

export default ReferralCode;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Client from "hooks/useClient";

const { api } = Client();

// Fetch all referral codes
export const getAllReferralCodes = createAsyncThunk(
  "referral/getAll",
  async () => {
    const response = await api.get("/api/referrals/get-all-referral-codes");
    return response.data.data;
  }
);

export const generateReferralCode = createAsyncThunk(
  "referral/generate",
  async ({ phone, maxDays, maxUsage }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/api/referrals/generate-referral-codes`,
        {
          phone,
          maxDays,
          maxUsage,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: error.message || "Unknown error" });
      }
    }
  }
);

// Update an existing referral code
export const updateReferralCode = createAsyncThunk(
  "referral/update",
  async ({ referredBy, daysToAdd, increaseUsage }, { rejectWithValue }) => {
    try {
      const response = await api.patch(
        `/api/referrals/update-referral-code?referredBy=${referredBy}`,
        {
          daysToAdd,
          increaseUsage,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: error.message || "Unknown error" });
      }
    }
  }
);

// Delete a referral code
export const deleteReferralCode = createAsyncThunk(
  "referral/delete",
  async (referredBy, { rejectWithValue }) => {
    try {
      const response = await api.delete(
        `/api/referrals/delete-referral-code?referredBy=${referredBy}`
      );
      return response.data; // Return the expected response structure
    } catch (error) {
      console.error("Error deleting referral:", error);
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: error.message || "Unknown error" });
      }
    }
  }
);

// Slice
const referralSlice = createSlice({
  name: "referral",
  initialState: {
    referralCodes: [],
    loading: false,
    generateLoading: false,
    updateLoading: false,
    deleteLoading: false, // New loading state for delete operation
    error: null,
  },
  extraReducers: (builder) => {
    builder
      // Fetch all referral codes
      .addCase(getAllReferralCodes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllReferralCodes.fulfilled, (state, action) => {
        state.loading = false;
        state.referralCodes = action.payload;
      })
      .addCase(getAllReferralCodes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Generate referral code
      .addCase(generateReferralCode.pending, (state) => {
        state.generateLoading = true;
        state.error = null;
      })
      .addCase(generateReferralCode.fulfilled, (state, action) => {
        state.generateLoading = false;
        state.referralCodes.push(action.payload);
      })
      .addCase(generateReferralCode.rejected, (state, action) => {
        state.generateLoading = false;
        state.error = action.error.message;
      })

      // Update referral code
      .addCase(updateReferralCode.pending, (state) => {
        state.updateLoading = true;
        state.error = null;
      })
      .addCase(updateReferralCode.fulfilled, (state, action) => {
        state.updateLoading = false;
        const updatedCodeIndex = state.referralCodes.findIndex(
          (item) => item.phone === action.meta.arg.referredBy
        );
        if (updatedCodeIndex !== -1) {
          state.referralCodes[updatedCodeIndex] = {
            ...state.referralCodes[updatedCodeIndex],
            ...action.payload,
          };
        }
      })
      .addCase(updateReferralCode.rejected, (state, action) => {
        state.updateLoading = false;
        state.error = action.payload
          ? action.payload.message
          : "Failed to update referral code.";
      })

      // Delete referral code
      .addCase(deleteReferralCode.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(deleteReferralCode.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.referralCodes = state.referralCodes.filter(
          (item) => item.phone !== action.meta.arg
        );
      })
      .addCase(deleteReferralCode.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error = action.payload
          ? action.payload.message
          : "Failed to delete referral code.";
      });
  },
});

export default referralSlice.reducer;
